/* Importing Bootstrap SCSS file. */
@import 'app/styles/bootstrap-overrides';
@import 'bootstrap/scss/bootstrap';

/* Importing Font Awesome CSS files. */
@import 'app/styles/fontawesome/css/fontawesome.min.css';
@import 'app/styles/fontawesome/css/brands.min.css';
@import 'app/styles/fontawesome/css/solid.min.css';
/* Import others as needed. Check font awesome's docs. */

/* Importing custom SCSS file. */
@import 'app/styles/_colors';
@import 'app/styles/_globals';

p,
li,
h1,
h2,
h3,
h4,
h5,
div,
label,
button,
em,
blockquote,
address,
pre,
abbr,
cite,
dfn,
kbd,
samp,
var,
mark,
ruby,
rt,
rp,
ins,
del {
  color: $color-primary; /* Use your desired primary color value here */
}

.bg-dark-subtle {
  background-color: #f5f5f5 !important;
}

input,
select,
textarea,
code,
sub,
sup,
time,
legend,
caption,
small,
s {
  color: $color-primary;
}

.grecaptcha-badge { visibility: hidden; }
