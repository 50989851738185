@import "./_colors";

// scss-docs-start color-variables
// $blue:    #0d6efd !default;
// $indigo:  #6610f2 !default;
// $purple:  #6f42c1 !default;
// $pink:    #d63384 !default;
// $red:     #dc3545 !default;
// $orange:  #fd7e14 !default;
// $yellow:  #ffc107 !default;
// $green:   #198754 !default;
// $teal:    #20c997 !default;
// $cyan:    #0dcaf0 !default;

// Custom colors for this app
$primary: $color-primary;
$secondary: $color-secondary;
$success: $color-success;
$danger:  $color-alert;
$warning: $color-warning;
$info:    $color-info;

$container-max-widths: (
  sm: 540px,
  md: 768px,
  lg: 960px,
  xl: 1200px,
  xxl: 1500px
);

$btn-padding-y-lg: 22px;
$btn-padding-x-lg: 22px;
$btn-padding-y: 15px;
$btn-padding-x: 15px;

.btn-light {
  color: $color-primary !important;
}
