$color-primary: #04213d;
$color-highlight: #4294ff;
$color-secondary: #ffc50b;
$color-background: #1598d2;
$color-light-background: #fcfcfc;
$color-dark-background: #f5f5f5;
$color-highlight-background: #fff9e2;
$color-highlight-2-background: #0573CE;
$color-dark: #333;
$color-white: #fff;
$color-input: #f7f7f7;
$color-input-text: #838383;

$color-button-primary: #528fd8;

$color-success: #4fba6f;
$color-alert: #fe646f;
$color-warning: #c6a332;
$color-info: #c8effe;

$color-plan-highlight: #bbf49b;
$color-plan-background-highlight: rgba(187, 244, 155, 0.41);

.color-primary {
  color: $color-primary;
}

.color-highlight {
  color: $color-highlight;
}

.color-secondary {
  color: $color-secondary;
}

.color-background {
  color: $color-background;
}

.color-light-background {
  color: $color-light-background;
}

.color-dark-background {
  color: $color-dark-background;
}

.color-highlight-background {
  color: $color-highlight-background;
}

.color-highlight-2-background {
  color: $color-highlight-2-background;
}

.color-dark {
  color: $color-dark;
}

.color-white {
  color: $color-white;
}

.color-input {
  color: $color-input;
}

.color-input-text {
  color: $color-input-text;
}

.color-button-primary {
  color: $color-button-primary;
}

.color-success {
  color: $color-success;
}

.color-alert {
  color: $color-alert;
}

.color-warning {
  color: $color-warning;
}

.color-info {
  color: $color-info;
}

.color-plan-highlight {
  color: $color-plan-highlight;
}

.color-plan-background-highlight {
  color: $color-plan-background-highlight;
}
