@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap');

body {
  font-family: 'Readex Pro', sans-serif;
  font-weight: 400;
  font-size: 18px;
}
.small-font {
  font-weight: 300;
}

h1,
.h1 {
  font-size: 58px;
  font-weight: 700;
}

h2,
.h2 {
  font-size: 52px;
  font-weight: 700;
}

h3,
.h3 {
  font-size: 42px;
  font-weight: 700;
}

h4,
.h4 {
  font-size: 38px;
  font-weight: 600;
}

h5,
.h5 {
  font-size: 32px;
  font-weight: 600;
}

h6,
.h6 {
  font-size: 28px;
  font-weight: 600;
}

.mb-6 {
  margin-bottom: 4em;
}
.mb-7 {
  margin-bottom: 5em;
}

.bigger-text {
  font-size: 24px;
  font-weight: 400;
}

.small-headings {
  font-size: 15px;
}

p,
.p {
  font-size: 18px;
  font-weight: 400;
}

.small-text {
  font-size: 14px;
  font-weight: 200;
}

strong,
.bold {
  font-weight: 600;
}

a {
  color: $color-button-primary;
  text-decoration: underline;

  &:not(.no-hover-effect):hover {
    transform: scale(1.1);
  }
}

.container-large {
  @extend .container-fluid !optional;
  padding: 0 100px;

  @media (max-width: 1200px) {
    padding: 0 80px;
  }
  @media (max-width: 992px) {
    padding: 0 50px;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
}

.yellow-brand {
  color: rgb(255, 197, 11);
}

.blue-brand,
h1.blue-brand,
h2.blue-brand,
h3.blue-brand,
h4.blue-brand,
h5.blue-brand,
h6.blue-brand,
p.blue-brand,
strong.blue-brand {
  color: rgb(82, 143, 216);
}

.btn-tertiary {
  color: $color-button-primary;
  background-color: $color-white;
  border: 3px solid $color-button-primary;

  @media (hover: hover) {
    &:hover {
      background-color: $color-button-primary;
      color: $color-white;
    }
  }
}

.btn-tertiary-danger {
  color: red !important;
  background-color: $color-white !important;
  border: 3px solid red !important;

  @media (hover: hover) {
    &:hover {
      background-color: red !important;
      color: $color-white !important;
    }
  }
}

.modal-lg {
  max-width: calc(100% - 15px);
  width: 1000px;
}

/*
xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1200px
*/

@media (max-width: 960px) {
  h1,
  .h1 {
    font-size: 45px;
    font-weight: 700;
  }

  h2,
  .h2 {
    font-size: 40px;
    font-weight: 700;
  }

  h3,
  .h3 {
    font-size: 32px;
    font-weight: 700;
  }

  h4,
  .h4 {
    font-size: 29px;
    font-weight: 600;
  }

  p,
  .p {
    font-size: 14px;
    font-weight: 400;
  }

  .bigger-text {
    font-size: 18px;
    font-weight: 400;
  }

  strong,
  .bold {
    font-weight: 600;
  }

  .btn-sm-full-width {
    width: 100%;
  }
  .btn.btn-lg {
    padding: 16px;
  }
  .btn:not(.btn-lg) {
    padding: 12px;
  }
}
